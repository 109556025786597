.AboutSection{
    width: 80%;
    margin: auto;
    /* height: 80vh */
}

.AboutUs{
    margin-top: 3em;
    margin: auto;
}

.BestWriter{
    width: 70%;
    margin-top: -2em;
    margin-left: 16%;
}

.gayYKf {
    background-color: green;
    width: 15px !important;
    height: 15px !important;
    font-size: 12px!important;
}

/* Extra Information styling section */

.titleText{
    font-size: 30px;
    letter-spacing: 0.5px;
    margin-left: 20%;
    font-family: 'Heebo', sans-serif;
    font-family: 'Open Sans', sans-serif;
}

.extraInformation{
    width: 80%;
    font-size: 15px;
    margin-left: 20%;
    font-family: 'Heebo', sans-serif;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.5px;
}

/* Second */

.BestWriter_2{
    width: 60%;
    margin-top: -2em;
    margin-left: 20%;
}

.titleText_2{
    font-size:30px;
    letter-spacing: 0.5px;
    margin-right: 20%;
    font-family: 'Heebo', sans-serif;
    font-family: 'Open Sans', sans-serif;
}

.extraInformation_2{
    width: 80%;
    font-size: 15px;
    margin-right: 20%;
    font-family: 'Heebo', sans-serif;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.5px;
}

.vertical-timeline-element-content p{
    font-size: 14px !important;
    color: gray;
}

:root {
    --raisin-black-2: hsl(245, 16%, 16%);
    --raisin-black-1: hsl(244, 17%, 19%);
    --footer-bg-top: #cfcfcf;
    --footer-bg-bottom: orange;
    --majorelle-blue: #e10b13; /* Changed blue color to #e10b13 */
    --ghost-white-1: hsl(240, 100%, 99%);
    --ghost-white-2: hsl(228, 50%, 96%);
    --white-opacity: hsla(0, 0%, 100%, 0.5);
    --independence: hsl(245, 17%, 27%);
    --lavender-web: hsl(247, 69%, 95%);
    --eerie-black: hsl(210, 11%, 15%);
    --cool-gray: hsl(244, 17%, 61%);
    --sapphire: #e10b13; /* Changed blue color to #e10b13 */
    --white: hsl(0, 0%, 100%);
    --dark-gray: hsl(0, 0%, 66%);
    --orange:hsl(39, 100%, 50%) ;
    
    /**
     * typography
     */
  
    --ff-quicksand: "Quicksand", sans-serif;
    --ff-mulish: "Mulish", sans-serif;
  
    --fs-1: 36px;
    --fs-2: 28px;
    --fs-3: 20px;
    --fs-4: 17px;
    --fs-5: 16px;
    --fs-6: 15px;
    --fs-7: 14px;
  
    --fw-500: 500;
    --fw-600: 600;
    --fw-700: 700;
  
    /**
     * transition
     */
  
    --transition: 0.25s ease;
  
    /**
     * spacing
     */
  
    --section-padding: 80px;
}

.container2 { padding-inline: 15px; }
li { list-style: none; }

.h1,
.h2,
.h3 {
    /* color: var(--independence); */
    /* font-family: var(--ff-mulish); */
    line-height: 1.2;
}

.h1 { font-size: var(--fs-1); }

.h2 { font-size: var(--fs-2); }

.h3 { font-size: var(--fs-3); }

.btn {
    font-size: var(--fs-6);
    font-weight: var(--fw-700);
    padding: 15px 30px;
    border-radius: 4px;
    transition: var(--transition);
}

.btn:is(:hover, :focus) { transform: translateY(-2px); }

.btn-primary {
    background: var(--majorelle-blue);
    color: var(--white);
}

.section-title { text-align: center; margin-top: 100px;}

.section-text {
    /* color: var(--cool-gray); */
    color: #CBD5E0;
    font-size: var(--fs-6);
    line-height: 1.7;
    text-align: center;
}

.container2 { padding-inline: 15px; }

.about{
    color: #000;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 14px;
    word-spacing: 2px;
    padding: 5%;
    width: 100%;
    margin: auto;
    padding-left: 10%;
    padding-right: 10%; 
    float: left;
}

.about h3{
    font-size: 24px;
    color: #e10b13; /* Changed blue color to #e10b13 */
}

.about p{
    font-size: 18px;
    color: #333 !important;
}

.about { padding-block: var(--section-padding); }

.about-content { margin-bottom: 30px; }
.about-icon {
  width: 60px;
  height: 60px;
  background: var(--lavender-web);
  display: grid;
  place-items: center;
  color: #e10b13; /* Changed blue color to #e10b13 */
  font-size: 40px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.about-title {
   margin-bottom: 10px; 
   /* margin-left: -300px; */
  }

.about-text {
  /* color: var(--cool-gray); */
  color: #CBD5E0;
  /* font-weight: var(--fw-500); */
  line-height: 1.8;
  margin-bottom: 20px;
}

.about-list {
  display: grid;
  gap: 20px;
}
.about-card {
  padding: 20px;
  text-align: center;
  box-shadow: 0 3px 12px hsla(233, 77%, 10%, 0.06);
  border-radius: 4px;
  transition: var(--transition);
  border: 2px solid #555;
  
}

.about-card:hover {
  background: #e10b13; /* Changed blue color to #e10b13 */
  transform: translateY(-5px);
  box-shadow: 0 5px 18px hsla(245, 67%, 59%, 0.4);
}

.about-card .card-icon {
  width: 200px;
  height: 200px;
  background: var(--lavender-web);
  display: grid;
  place-items: center;
  color: #e10b13; /* Changed blue color to #e10b13 */
  font-size: 28px;
  border-radius: 50%;
  margin-inline: auto;
  margin-bottom: 20px;
  transition: var(--transition);
}

.about-card:hover .card-icon {
  background: hsla(0, 0%, 100%, 0.15);
  color: var(--white);
  box-shadow: 0 0 0 8px hsla(0, 0%, 100%, 0.05);
}
.card-icon {
    width: 100%;
    height: auto;
    overflow: hidden; 
  }
  
  .card-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border-radius: 50%; */
  }
  

.about-card .card-title {
  margin-bottom: 10px;
  transition: var(--transition);
}

.about-card:hover .card-title { color: var(--white); }

.about-card .card-text {
  color: var(--cool-gray);
  font-size: var(--fs-6);
  font-weight: var(--fw-500);
  line-height: 1.8;
  transition: var(--transition);
}

.about-card:hover .card-text { color: hsla(0, 0%, 100%, 0.5); }

/* Features2 Section */

.features2 {
  padding-block: var(--section-padding);
  /* background: var(--ghost-white-1); */
  width: 80%;
  margin: auto;
}

.features2 .section-title { margin-bottom: 15px; }

.features2 .section-text { margin-bottom: 60px; }

.features2-wrapper:not(:last-child) { margin-bottom: 80px; }

.features2-banner { margin-bottom: 35px; }

.features2-banner img { width: 100%; }

.features2-content-subtitle {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--fs-5);
  color: var(--eerie-black);
  margin-bottom: 20px;
}

.features2-content-subtitle ion-icon {
  color: #e10b13; /* Changed blue color to #e10b13 */
  font-size: 20px;
}

.features2-content-title {
  font-size: var(--fs-2);
  font-family: var(--ff-mulish);
  color: var(--independence);
  font-weight: var(--fw-600);
  margin-bottom: 25px;
}

.features2-content-title strong { font-weight: var(--fw-700); }

.features2-content-text {
  font-size: var(--fs-6);
  /* color: var(--cool-gray); */
  color: #fff;
  line-height: 1.7;
  margin-bottom: 25px;
}

.features2-list { 
  margin-bottom: 40px; 
  color: #fff;
}

.features2-list-item {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  font-size: var(--fs-5);
  /* color: var(--cool-gray); */
  margin-bottom: 10px;
}

.features2-list-item ion-icon { font-size: 23px; }

.features2-list-item p { width: calc(100% - 28px); }

.features2-wrapper:last-child {
  display: flex;
  flex-direction: column-reverse;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

/* About Products */

.AboutProducts { padding-block: var(--section-padding); }

.AboutProducts .section-title { margin-bottom: 20px; }

/* .AboutProducts .section-text { margin-bottom: 40px; } */

.AboutProducts-list {
  display: grid;
  gap: 30px;
}

.AboutProducts-banner { margin-bottom: 20px; }

.AboutProducts-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.AboutProducts-meta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  font-size: var(--fs-6);
  text-transform: uppercase;
  color: var(--cool-gray);
  margin-bottom: 10px;
}

.AboutProducts-meta span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.AboutProducts-title {
  font-size: var(--fs-3);
  color: var(--independence);
}

.AboutProducts-text {
  color: var(--cool-gray);
  font-size: var(--fs-6);
  line-height: 1.7;
  margin-bottom: 15px;
}

.AboutProducts-link-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #e10b13; /* Changed blue color to #e10b13 */
  font-weight: var(--fw-600);
}

.AboutProducts-link-btn:is(:hover, :focus) { color: var(--sapphire); }


/* Responsiveness */

@media (min-width: 450px) {
  .about-card .card-text {
    max-width: 300px;
    margin-inline: auto;
  }
  
}

/* Responsive for larger than 576px screen */

@media (min-width: 576px) {

   :root {

      --fs-1: 52px;

  }

  .container2 {
    max-width: 525px;
    margin-inline: auto;
  }

  .section-text {
    max-width: 460px;
    margin-inline: auto;
    margin-top: 10px;
  }

}

@media (min-width: 768px) {

  .container2 { max-width: 720px; }

  .section-text { max-width: 645px; }

  .hero :is(.hero-text, .form-text, .hero-form) { max-width: 520px; }

  .hero-banner {
    max-width: 600px;
    margin-inline: auto;
  }

  .about-list { grid-template-columns: 1fr 1fr; }

  .wrapper-flex .input-wrapper { width: 50%; }

}

/**
 * responsive for larger than 992px screen
 */

@media (min-width: 992px) {

  .container2 { max-width: 950px; }

  .section-text { max-width: 450px; }

  .about .container2 {
    display: flex;
    align-items: center;
    gap: 50px;
  }

  .about-content {
    /* margin-bottom: 20px; */
    width: 80%;
  }

  .about-list {
    gap: 30px;
    padding-bottom: 50px;
  }

  .about-list li:nth-child(odd) { transform: translateY(50px); }
  .features2-wrapper {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 90px;
  }

  .features2-wrapper:not(:last-child) { margin-bottom: 100px; }

  .features2-wrapper:last-child .features2-banner {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
  .AboutProducts-list { grid-template-columns: repeat(3, 1fr); }

  .AboutProducts .section-text { margin-bottom: 50px; }
  

}

/* Responsive for larger than 1200px screen */

@media (min-width: 1200px) {
 .container2 { max-width: 1150px; }
}
